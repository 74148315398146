<template>
  <a-modal
    title="修改密码"
    okText="确认修改"
    :maskClosable="false"
    :visible="modifyPassword"
    :confirm-loading="modifyPasswordLoading"
    @ok="savePassword"
    @cancel="cancelPassword"
  >
    <a-form-model ref="ruleForm" :model="ruleForm" :rules="rules" v-bind="layout">
      <a-form-model-item label="原密码" prop="oldPassword">
        <a-input v-model.trim="ruleForm.oldPassword" type="password" />
      </a-form-model-item>
      <a-form-model-item label="新密码" prop="newPassword">
        <a-input v-model.trim="ruleForm.newPassword" type="password" />
      </a-form-model-item>
      <a-form-model-item label="确认新密码" prop="confirmPassword">
        <a-input v-model.trim="ruleForm.confirmPassword" type="password" />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
import md5 from 'md5'
import { isPassword } from '@/utils/validate'
import api from '@/api'
export default {
  data() {
    let validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入新密码'))
      } else if (!isPassword(value)) {
        callback(new Error('密码由8-12位字母和数字组成'))
      } else {
        if (this.ruleForm.confirmPassword !== '') {
          this.$refs.ruleForm.validateField('confirmPassword')
        }
        callback()
      }
    }
    let validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请确认新密码'))
      } else if (value !== this.ruleForm.newPassword) {
        callback(new Error('2次输入新密码不匹配'))
      } else {
        callback()
      }
    }
    return {
      modifyPassword: false,
      modifyPasswordLoading: false,
      ruleForm: {
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
      },
      rules: {
        oldPassword: [{ required: true, message: '请填写原密码' }],
        newPassword: [{ required: true, validator: validatePass, trigger: 'blur' }],
        confirmPassword: [{ required: true, validator: validatePass2, trigger: 'blur' }]
      },
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 16 }
      }
    }
  },
  methods: {
    open() {
      this.toggle()
    },
    toggle(flag = true) {
      this.modifyPassword = flag
    },
    savePassword() {
      this.submitForm('ruleForm')
    },
    cancelPassword() {
      this.toggle(false)
      this.resetForm('ruleForm')
    },
    submitForm(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          try {
            this.modifyPasswordLoading = true
            const { oldPassword, newPassword } = this.ruleForm
            await api.student.modifyPassword({ oldPassword: md5(oldPassword), newPassword: md5(newPassword) })
            this.modifyPasswordLoading = false
            this.cancelPassword()
            this.$message.info('密码修改成功')
          } catch (e) {
            this.modifyPasswordLoading = false
          }
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    }
  }
}
</script>
