<!--
 * @Author: xiaziwen
 * @Date: 2021-01-10 12:23:52
 * @LastEditors: xiaziwen
 * @LastEditTime: 2021-01-10 13:33:09
 * @FilePath: \crm-education\src\views\student\my_textbook_send\index.vue
-->
<template>
  <a-card :bordered="false" class="cus-card no-border">
    <template slot="title">
      <div class="section-header dec d-flex align-center justify-between flex-wrap">
        <h2 class="base-title fs-18">个人信息</h2>
        <p class="fs-14 color-gray link color-blue" @click="$refs.modifyPassword.open()">
          修改密码
        </p>
      </div>
    </template>
    <a-form :form="form" :label-col="labelCol" :wrapper-col="wrapperCol" @submit="handleSubmit">
      <a-form-item label="学号">
        <a-input disabled placeholder="请输入学号" v-decorator="generateForm.studNo" />
      </a-form-item>
      <a-form-item label="身份证号">
        <a-input disabled placeholder="请输入身份证号" v-decorator="generateForm.idNo" />
      </a-form-item>
      <a-form-item label="姓名">
        <a-input disabled placeholder="请输入姓名" v-decorator="generateForm.realName" />
      </a-form-item>
     <!-- <a-form-item label="手机号">
        <a-input placeholder="请输入手机号" v-decorator="generateForm.phoneNumber" />
      </a-form-item>
      <a-form-item label="邮箱">
        <a-input placeholder="请输入邮箱" v-decorator="generateForm.email" />
      </a-form-item>-->
      <a-form-item label="省市区">
        <a-cascader :options="cityData" v-decorator="generateForm.location" placeholder="请选择省市区" />
      </a-form-item>
      <a-form-item label="通讯地址">
        <a-input
          v-decorator="generateForm.studAddress"
          placeholder="通讯地址将会用户寄送教材，学习证件等，请认真填写真实地址"
        />
      </a-form-item>
      <a-form-item label="头像">
        <a-upload
          class="student-thumba"
          v-decorator="generateForm.studImg"
          list-type="picture-card"
          :customRequest="handleUpload"
          :file-list="fileList"
          @preview="handlePreview"
          @change="handleChange"
        >
          <div>
            <a-icon type="plus" />
            <div class="ant-upload-text">
              点击上传头像
            </div>
          </div>
        </a-upload>
      </a-form-item>
      <a-row>
        <a-col :offset="5">
          <a-form-item>
            <a-button type="primary" html-type="submit" :loading="saveBtnLoading">保存 </a-button>
            <a-button class="ml-20" type="default">取消 </a-button>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <modify-password ref="modifyPassword"></modify-password>
  </a-card>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import ModifyPassword from './c/ModifyPassword'
import helper from '@/utils/helper'
import { isPhone, isEncryptPhone, isEmail } from '@/utils/validate'
import city from '@/config/city'
import api from '@/api'
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}
export default {
  name: 'my_textbook_send',
  components: {
    ModifyPassword
  },
  data() {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 }
      },
      cityData: city,
      saveBtnLoading: false,
      previewVisible: false,
      previewImage: '',
      generateForm: {
        studNo: [
          'studNo',
          {
            rules: [{ required: true, message: '学号不能为空' }],
            initialValue: ''
          }
        ],
        idNo: [
          'idNo',
          {
            rules: [{ required: true, message: '身份证号不能为空' }],
            initialValue: ''
          }
        ],
        realName: [
          'realName',
          {
            rules: [{ required: true, message: '姓名不能为空' }],
            initialValue: ''
          }
        ],
        /*phoneNumber: [
          'phoneNumber',
          {
            rules: [{ required: true, message: '手机号不能为空' }, { validator: this.validatePhone }],
            initialValue: ''
          }
        ],
        email: [
          'email',
          {
            rules: [{ required: true, message: '邮箱不能为空' }, { validator: this.validateEmail }],
            initialValue: ''
          }
        ],*/
        location: [
          'location',
          {
            rules: [{ required: true, message: '省市区不能为空' }],
            initialValue: []
          }
        ],
        studAddress: [
          'studAddress',
          {
            rules: [{ required: true, message: '通讯地址用户物料寄送不能为空!' }],
            initialValue: ''
          }
        ],
        studImg: [
          'studImg',
          {
            rules: [{ required: true, message: '图像不能为空' }],
            initialValue: []
          }
        ]
      },
      fileList: []
    }
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'userInfo' })
  },
  computed: {
    ...mapState(['user'])
  },
  created() {
    this.getInfo()
  },
  mounted() {},
  methods: {
    ...mapMutations(['setUser']),
    async getInfo() {
      const { data } = await api.student.getStudentInfo()
      this.studentInfo = data
      Object.keys(this.generateForm).forEach(field => {
        if (data[field]) {
          this.generateForm[field][1].initialValue = data[field]
        }
        if (field === 'location' && data['province'] && data['city'] && data['area']) {
          this.generateForm['location'][1].initialValue = [data['province'], data['city'], data['area']]
        }
      })
      if (data.studImg) {
        this.fileList = [
          {
            uid: Math.random() * 1000000000,
            name: 'avatar',
            status: 'done',
            url: data.studImg
          }
        ]
      }
    },
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFieldsAndScroll(async (err, values) => {
        if (!err) {
          this.saveBtnLoading = true
          const { data } = await api.student.changeStudentInfo({
            ...values,
            studImg: this.fileList[0].url,
            province: values.location[0],
            city: values.location[1],
            area: values.location[2]
          })
          this.saveBtnLoading = false
          this.$message.success('个人信息修改成功')
        }
      })
    },
    handleCancel() {
      this.previewVisible = false
    },
    async handleUpload(file) {
      const formData = new FormData()
      formData.append('file', file.file)
      formData.append('studId', helper.getUserInfo('userId'))
      const { data } = await api.student.uploadStudentThumba(formData)
      this.setUser({ ...this.user, studentImg: data })
      helper.updateUserInfo('studentImg', data)
      this.fileList = [
        {
          uid: file.file.uid,
          name: file.file.name,
          status: 'done',
          url: data
        }
      ]
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    handleChange({ fileList }) {
      // this.fileList = fileList
    },
    validatePhone(rule, value, callback) {
      const form = this.form
      const phoneVal = form.getFieldValue('phoneNumber')
      const noEncrypt = value && !isPhone(phoneVal)
      const encrypt = value && !isEncryptPhone(phoneVal)
      if (noEncrypt) {
        if (encrypt) {
          callback('手机号格式不正确')
        } else {
          callback()
        }
      } else {
        callback()
      }
    },
    validateEmail(rule, value, callback) {
      const form = this.form
      if (value && !isEmail(form.getFieldValue('email'))) {
        callback('邮箱格式不正确')
      } else {
        callback()
      }
    }
  }
}
</script>
<style lang="scss">
.student-thumba {
  .ant-upload-select {
    width: 120px;
    height: 150px;
  }
  .ant-upload-list-picture-card-container {
    width: 120px;
    height: 150px;
    .ant-upload-list-item {
      width: 120px;
      height: 150px;
      padding: 0;
    }
  }
  .ant-upload-list-item-actions {
    display: none;
  }
}
</style>
